import React, { Component } from 'react';
import { getAPI } from '../../Base/API';
import { ActiveInactiveStatusBadge, FormatAmountNumber, StyledCard } from '../../Base/Common/CommonUIComponents';
import { CustomTable } from '../../Base/Common/CustomTable';
import { FormattedMessage, injectIntl } from "react-intl";
import { handleNotification } from '../../Base/Common/Notification';
import { Col, Input, Row, Button } from 'reactstrap';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { getRightVoucherId, getStatus } from '../../Base/Common/ReferenceDataFunctions';
import CustomSelect from '../../Base/Common/CustomSelect';


class UsageReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            fromDate: moment().subtract(365, 'days'),
            toDate: moment(),
            hotelList: [],
            hotelId: null
        }
    }

    componentDidMount() {
        this.getUsageReport();
        this.getHotels();
    }

    getUsageReport = () => {
        const { fromDate, toDate, hotelId } = this.state;
        this.setState({ block: true });

        const param = hotelId ? `&hotelId=${hotelId}` : '';

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response) {
                    this.setState({ usageReport: data.response });
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/Voucher/Voucher/v1/usageReport?fromDate=${moment(fromDate).format("YYYY-MM-DD")}&toDate=${moment(toDate).format("YYYY-MM-DD")}` + param);
    }
    
    getHotels = () => {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                const hotelList = data.response[0].hotels?.map((el) => ({ value: el.hotelId, label: el.name2 })) || [];

                this.setState({ hotelList, block: false });
            }
        }, '/api/Voucher/Hotel/v1/hotel');

    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }


    render() {
        const { block, error, usageReport, fromDate, toDate, hotelList, hotelId } = this.state;

        const columns = [
            {
                dataField: 'voucherId',
                text: this.props.intl.formatMessage({ id: "OperationManagement.CardId" }),
                sort: true,
                formatter: (cell, row) => getRightVoucherId(cell, row.voucherIdFirst8),
                csvFormatter: (cell, row) => getRightVoucherId(cell, row.voucherIdFirst8)
            },
            {
                dataField: 'createdAt',
                text: this.props.intl.formatMessage({ id: "OperationManagement.IssueDate" }),
                sort: true,
                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : null,
                csvFormatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : null
            },
            {
                dataField: 'voucherTypeCode',
                text: this.props.intl.formatMessage({ id: "OperationManagement.CardType" }),
                sort: true
            },
            {
                dataField: 'hotelId',
                text: this.props.intl.formatMessage({ id: "VoucherOperationDetails.Hotel" }),
                sort: true,
                formatter: (cell) => cell ? hotelList.find(el => el.value === cell)?.label || cell : '',
                csvFormatter: (cell) => cell ? hotelList.find(el => el.value === cell)?.label || cell : ''
            },
            {
                dataField: 'validUntil',
                text: this.props.intl.formatMessage({ id: "VoucherOperationDetails.Vality" }),
                sort: true,
                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : '',
                csvFormatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : ''
            },
            {
                dataField: 'status',
                text: this.props.intl.formatMessage({ id: "VoucherOperationDetails.Status" }),
                sort: true,
                formatter: (cell) => this.props.intl.formatMessage({ id: `ReferenceDataFunctions.${cell}` }),
                csvFormatter: (cell) => this.props.intl.formatMessage({ id: `ReferenceDataFunctions.${cell}` }),
            },
            {
                dataField: 'saleAmount',
                text: this.props.intl.formatMessage({ id: "VoucherOperationDetails.PayedAmount" }),
                sort: true,
                formatter: (cell) => cell ? <FormatAmountNumber value={cell} /> : ''
            },
            {
                dataField: 'voucherAmount',
                text: this.props.intl.formatMessage({ id: "VoucherOperationDetails.VoucherAmount" }),
                sort: true,
                formatter: (cell) => cell ? <FormatAmountNumber value={cell} /> : ''
            },
            {
                dataField: 'redeemsAmount',
                text: this.props.intl.formatMessage({ id: "VoucherOperationDetails.Redeems" }),
                sort: true,
                formatter: (cell) => cell ? <FormatAmountNumber value={cell} /> : ''
            },
            {
                dataField: 'availableBalance',
                text: this.props.intl.formatMessage({ id: "VoucherOperationDetails.AvailableBalance" }),
                sort: true,
                formatter: (cell) => cell ? <FormatAmountNumber value={cell} /> : ''
            },
        ];
        
        return (
            <StyledCard icon={'fas fa-file-alt'} title={'navbar.UsageReport'} error={error} block={block}>
                <Row className="mb-3">
                    <Col sm={3}>
                        <CustomSelect
                            icon="fas fa-hotel"
                            options={hotelList}
                            isClearable
                            isSearchable
                            placeholder={<FormattedMessage id="generic.Hotel"/>}
                            value={hotelList.find(el => el.value === hotelId) || ''}
                            onChange={(combo) => this.handleSelect('hotelId', combo)}
                        />
                    </Col>
                    <Col sm={3}>
                        <DateRangePicker
                            startDateId="vouchersValidFrom"
                            startDate={fromDate || null}
                            isOutsideRange={day => day > moment() || day < moment().subtract(365, 'days')}
                            endDate={toDate || null}
                            endDateId="vouchersValidUntil"
                            onDatesChange={({ startDate, endDate }) => this.setState({ fromDate: startDate, toDate: endDate })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                            small={true}
                            required
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                        />
                    </Col>
                    <Col className="text-right">
                        <Button className="btn btn-host btn-sm" onClick={this.getUsageReport}>
                            <i className="fas fa-search" />
                        </Button>
                    </Col>
                </Row>
                <CustomTable
                    data={usageReport || []}
                    columns={columns}
                    showTotal={true}
                    exportCSV={true}
                />
            </StyledCard>
        );
    }
}

export default injectIntl(UsageReport);