import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import Home from './components/Home';
import { VoucherManagment } from './components/Config/VoucherManagement';
import { EditVoucher } from './components/Config/EditVoucher';
import CardConfigWizard from './components/Config/CardConfigWizard';

import OperationManagement from './components/Operation/OperationManagement';
import VoucherOperationDetails from './components/Operation/VoucherOperationDetails';
import { Profile } from './components/Profile/Profile';
import HotelList from './components/Hotel/HotelList';
import Hotel from './components/Hotel/Hotel';
import { TermsConditions } from './components/Config/TermsConditions';
import { OnlineSales } from './components/Operation/OnlineSales';
import { OnlineSalesDetail } from './components/Operation/OnlineSalesDetail';
import ManageUser from './components/Profile/ManageUser';
import PaymentManagement from './components/Payment/PaymentManagement';
import PaymentDashboard from './components/Payment/PaymentDashboard';

import { AdminHotelList } from './components/Admin/Hotel/AdminHotelList';
import { PaymentKeyManagement } from './components/Admin/PaymentKeyManagement';
import { ApiGatewayUserManagement } from './components/Admin/ApiGateway/ApiGatewayUserManagement';
import { ApiGatewayUserDetails } from './components/Admin/ApiGateway/ApiGatewayUserDetails';
import AdminHotelGroupList from './components/Admin/HotelGroup/AdminHotelGroupList';
import ManageScheduleTask from './components/Admin/ScheduleTask/ManageScheduleTask';
import AdminUserManagement from './components/Admin/AdminUserManagement';
import VoucherTemplates from './components/Config/VoucherTemplates'
import PMSConnectivity from './components/Config/PMSConnectivity'
import VoucherIssueAutomation from './components/Config/VoucherIssueAutomation'
import VoucherIssueAutomationDetails from './components/Config/VoucherIssueAutomationDetails'
import ReservationVouchers from './components/Operation/ReservationVouchers/ReservationVouchers';
import ReservationVoucherDetail from './components/Operation/ReservationVoucherDetail';
import ReferenceData from './components/Config/ReferenceData';
import HotelConfig from './components/Admin/HotelConfig/HotelConfig';
import LogsDashboard from './components/Config/LogsDashboard';
import Licenses from './components/Admin/Licenses/Licenses';
import HotelLicenses from './components/Admin/Licenses/HotelLicenses';
import PriceList from './components/Admin/PriceList/PriceList';
import PaymentPositions from './components/Admin/PaymentPositions/PaymentPositions';

import SalesDashboard from './components/Analytics/SalesDashboard';
import { NotificationContainer } from "react-notifications";
import Authorization from './components/Base/Authorization';
import UseVoucher from './components/Operation/UseVoucher';

import 'react-dates/lib/css/_datepicker.css';
import './css/react_dates_overrides.css';
import 'react-notifications/lib/notifications.css';
import './custom.css'
import ProfilesDashboard from './components/Profiles/ProfilesDashboard';
import ProfileDetail from './components/Profiles/ProfileDetail';
import { Promotions } from './components/Config/Promotions';
import { Shopping } from './components/Operation/Shopping';
import Backends from './components/Admin/Backends/Backends';
import BackendDetails from './components/Admin/Backends/BackendDetails';
import VoucherTags from './components/Config/VoucherTags/VoucherTags';
import ResetCache from './components/Admin/ResetCache/ResetCache';
import PlatformDeepDive from './components/Admin/PlatformDeepDive/PlatformDeepDive';
import UsageReport from './components/Operation/UsageReport/UsageReport';


export default class App extends Component {
    static displayName = App.name;
    
    render() {

        return (
            <Layout>
                <Route exact path='/' component={SalesDashboard} />
                <Route exact path='/VoucherManagment' component={VoucherManagment} />

                <Authorization
                    perform="editVoucher:view"
                    yes={() => (
                        <>
                            <Route path='/EditVoucher/:id?' component={EditVoucher} />
                            <Route path='/CardConfigWizard/:id?' component={CardConfigWizard} />
                        </>
                    )}
                    no={() => <div></div>}
                />

               
                    
                <Route exact path='/OperationManagement' component={OperationManagement} />
                <Route path='/VoucherOperationDetails/:id?' component={VoucherOperationDetails} />
                <Route exact path='/Profile' component={Profile} />
                
                <Authorization
                    perform="hotel:view"
                    yes={() => (
                        <Route path='/Hotel/:id?' component={Hotel} />
                    )}
                    no={() => <div></div>}
                />

                <Authorization
                    perform="hotelList:view"
                    yes={() => (
                        <Route exact path='/HotelList' component={HotelList} />
                    )}
                    no={() => <div></div>}
                />

                <Route exact path='/TermsConditions' component={TermsConditions} />
                <Route exact path='/Shopping' component={Shopping} />
                <Route exact path='/OnlineSales' component={OnlineSales} />
                <Route path='/OnlineSalesDetail/:id?' component={OnlineSalesDetail} />

                <Route exact path='/ReservationVouchers' component={ReservationVouchers} />
                <Route path='/ReservationVoucherDetail/:id?' component={ReservationVoucherDetail} />
                

                <Authorization
                    perform="manageUser:view"
                    yes={() => (
                        <Route exact path='/ManageUser' component={ManageUser} />
                    )}
                    no={() => <div></div>}
                />

                <Authorization
                    perform="paymentManagement:view"
                    yes={() => (
                        <Route exact path='/PaymentManagement' component={PaymentManagement} />
                    )}
                    no={() => <div></div>}
                />

                <Route exact path='/VoucherTemplates' component={VoucherTemplates} />
                <Route exact path='/VoucherIssueAutomation' component={VoucherIssueAutomation} />
                <Route path='/VoucherIssueAutomationDetails/:id?' component={VoucherIssueAutomationDetails} />
                <Route exact path='/ReferenceData' component={ReferenceData} />
                <Route exact path='/PMSConnectivity' component={PMSConnectivity} />
                <Route exact path='/LogsDashboard' component={LogsDashboard} />

                <Route exact path='/VoucherTags' component={VoucherTags} />                

                <Authorization
                    perform="adminHotelList:view"
                    yes={() => (
                        <Route exact path='/AdminHotelList' component={AdminHotelList} />
                    )}
                    no={() => <div></div>}
                />

                <Authorization
                    perform="paymentKeyManagement:view"
                    yes={() => (
                        <Route exact path='/PaymentKeyManagement' component={PaymentKeyManagement} />
                    )}
                    no={() => <div></div>}
                />

                <Authorization
                    perform="apiGatewayUser:view"
                    yes={() => (
                        <Route exact path='/ApiGatewayUserManagement' component={ApiGatewayUserManagement} />
                    )}
                    no={() => <div></div>}
                />
                
                <Authorization
                    perform="apiGatewayUserDetails:view"
                    yes={() => (
                        <Route path='/ApiGatewayUserDetails/:id?' component={ApiGatewayUserDetails} />
                    )}
                    no={() => <div></div>}
                />
                
                <Authorization
                    perform="adminHotelGroupList:view"
                    yes={() => (
                        <Route exact path='/AdminHotelGroupList' component={AdminHotelGroupList} />
                    )}
                    no={() => <div></div>}
                />

                <Authorization
                    perform="manageScheduleTask:view"
                    yes={() => (
                        <Route exact path='/ManageScheduleTask' component={ManageScheduleTask} />
                    )}
                    no={() => <div></div>}
                />


                <Authorization
                    perform="adminUserManagement:view"
                    yes={() => (
                        <Route exact path='/AdminUserManagement' component={AdminUserManagement} />
                    )}
                    no={() => <div></div>}
                />

                <Authorization
                    perform="salesDashboard:view"
                    yes={() => (
                        <Route exact path='/SalesDashboard' component={SalesDashboard} />
                    )}
                    no={() => <div></div>}
                />

                <Route exact path='/UseVoucher' component={UseVoucher} />
                <Authorization
                    perform="HotelConfig:view"
                    yes={() => (
                        <Route exact path='/HotelConfig' component={HotelConfig} />
                    )}
                    no={() => <div></div>}
                />
                <Route exact path='/ProfilesDashboard' component={ProfilesDashboard} />
                <Route path='/Profiles/:id' component={ProfileDetail} />

                <Authorization
                    perform="paymentDashboard:view"
                    yes={() => (
                        <Route path='/PaymentDashboard/:id?' component={PaymentDashboard} />
                    )}
                    no={() => <div></div>}
                />

                <Route exact path='/UsageReport' component={UsageReport} />

                <Authorization
                    perform="licenses:view"
                    yes={() => (
                        <Route exact path='/Licenses' component={Licenses} />
                    )}
                    no={() => <div></div>}
                />

                <Authorization
                    perform="hotelLicenses:view"
                    yes={() => (
                        <Route exact path='/HotelLicenses' component={HotelLicenses} />
                    )}
                    no={() => <div></div>}
                />

                <Authorization
                    perform="priceList:view"
                    yes={() => (
                        <Route exact path='/PriceList' component={PriceList} />
                    )}
                    no={() => <div></div>}
                />

                <Authorization
                    perform="paymentPosition:view"
                    yes={() => (
                        <Route exact path='/PaymentPositions' component={PaymentPositions} />
                    )}
                    no={() => <div></div>}
                />

                <Route exact path='/Promotions' component={Promotions} />


                <Authorization
                    perform="admin:view"
                    yes={() => (
                        <>
                            <Route exact path='/Backends' component={Backends} />
                            <Route exact path='/BackendDetails/:id?' component={BackendDetails} />
                            <Route exact path='/ResetCache' component={ResetCache} />
                            <Route exact path='/PlatformDeepDive' component={PlatformDeepDive} />
                        </>
                    )}
                    no={() => <div></div>}
                />

                <NotificationContainer />
            </Layout>
        );
    }
}
